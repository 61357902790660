import {MenuCreateOptionInterface} from 'kernel/types/module'
// @ts-ignore
import siteConfig from '@/config/site.js'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        {
          icon: 'fa fa-comment-dots',
          create: (options: MenuCreateOptionInterface) => {
            if(!siteConfig.feature.smsModule) return false
            return options.hasRole(['ROLE_ADMIN', 'ROLE_SMS'])
          },
          label: this.vm.$t('page.sms-log'),
          route: { name: 'sms' },
        },
      ],
    }
  }
}

export default () => (new menu()).get()
